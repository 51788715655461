import type { BlockedRange, ExpandedRvDetails, Nullable } from '~/types'

// Block every dates after the selling date
export const getRvCalendarBlockedRangesFromSellingDate = (rvRVSellingDate: Nullable<ExpandedRvDetails['RVSellingDate']>) => {
  if (!rvRVSellingDate) {
    return []
  }

  const sellingDate = dates(dates.utc(rvRVSellingDate).format('LL'), 'LL').toDate()
  return [{ start: sellingDate, end: null }]
}

// Block ranges outside of Booking season
export const getRvCalendarBlockedRangesFromBookingSeason = (rvBookingSeasonStart: Nullable<ExpandedRvDetails['BookingSeasonStart']>, rvBookingSeasonEnd: Nullable<ExpandedRvDetails['BookingSeasonEnd']>) => {
  const blockedRanges: BlockedRange[] = []

  if (!rvBookingSeasonStart || !rvBookingSeasonEnd) {
    return blockedRanges
  }

  const isEndBeforeStart = rvBookingSeasonEnd < rvBookingSeasonStart

  // Start the date range from the end of the season
  const blockStart = rvBookingSeasonEnd.toString()
  const blockStartDay = blockStart.slice(-2)
  const blockStartMonth = blockStart.slice(0, -2)
  const blockStartYear = dates().year() - (isEndBeforeStart ? 0 : 1)

  // End the date range before the start of the season
  const blockEnd = rvBookingSeasonStart.toString()
  const blockEndDay = blockEnd.slice(-2)
  const blockEndMonth = blockEnd.slice(0, -2)
  const blockEndYear = dates().year()

  /**
   * We support 2 years in the future
   */
  for (let i = 0; i < 3; i++) {
    blockedRanges.push({
      start: dates(`${blockStartYear + i}-${blockStartMonth}-${blockStartDay}`, 'YYYY-M-DD')
        .add(1, 'day')
        .toDate(),
      end: dates(`${blockEndYear + i}-${blockEndMonth}-${blockEndDay}`, 'YYYY-M-DD')
        .subtract(1, 'day')
        .toDate(),
    })
  }

  return blockedRanges
}

// Block ranges from existing bookings and blocked dates
export const getRvCalendarBlockedDates = (rvCalendars: Nullable<ExpandedRvDetails['Calendars']>) => {
  const blockedRanges: BlockedRange[] = []

  rvCalendars?.forEach((range) => {
    const startDate = dates(range.StartDate).startOf('day')
    const endDate = dates(range.EndDate).startOf('day')

    // If startDate is the same as the endDate, it's a blocked date
    if (endDate.diff(startDate, 'days') === 0) {
      blockedRanges.push(startDate.toDate())
    }
    else {
    // Otherwise, it's an existing booking
      blockedRanges.push({
        start: startDate.startOf('day').add(1, 'day').toDate(),
        end: endDate.startOf('day').subtract(1, 'day').toDate(),
      })
    }
  })

  return blockedRanges
}
